export type AppListItem = {
  key: string;
  title: string;
  description: string;
  second_description?: string; // will appear in a new line under 'description'
  icon: string;
  url: string;
};

export const defaultApps: AppListItem[] = [
  {
    key: "confluence",
    title: "Confluence",
    description:
      "Publish, organize, and access company information in one central location",
    url: "https://gotosage.atlassian.net/wiki/spaces/a/",
    icon: require("../images/icons/confluence.png"),
  },
  {
    key: "timesheet",
    title: "Timesheet",
    description: "View and submit timesheets",
    url: "https://timesheets.gotosage.com",
    icon: require("../images/icons/timesheet.png"),
  },
  {
    key: "jseas",
    title: "JSEA's",
    description: "Job Safety and Analysis system",
    url: "https://jseas.gotosage.com/",
    icon: require("../images/icons/jseas.png"),
  },
  {
    key: "service_desk",
    title: "Service Desk",
    description: "Make a request",
    second_description: "IT | Workday | Finance",
    url: "https://id.atlassian.com/login?continue=https%3A%2F%2Fgotosage.atlassian.net%2Fservicedesk%2Fcustomer%2Fportals",
    icon: require("../images/icons/it-support.png"),
  },
  {
    key: "pronto",
    title: "Pronto",
    description: "Enterprise resource planning software",
    url: "https://sage.prontohosted.com.au",
    icon: require("../images/icons/pronto.png"),
  },
  // Put any new apps below the top 5 in alphabetical order by title
  {
    key: "archive_search",
    title: "Archive Search",
    description: "Elastic Workspace Search",
    url: "https://gotosage-search.ent.australiaeast.azure.elastic-cloud.com/ws/search",
    icon: require("../images/icons/workplace-search-logo.svg"),
  },
  {
    key: "bullhorn_embedded_expertise",
    title: "Bullhorn-Embedded Expertise",
    description: "Bullhorn-Embedded Expertise",
    url: "https://www.bullhornstaffing.com/BullhornStaffing/SAML/Portal.cfm?idp=https%3A%2F%2Fsts%2Ewindows%2Enet%2Fad55c8ad%2D3be0%2D46a2%2D8468%2D516c6767138f%2F&audience=api%3A%2F%2Fbullhornstaffing%2Ecom",
    icon: require("../images/icons/bullhorn.png"),
  },
  {
    key: "canvas_lms",
    title: "Canvas LMS",
    description:
      "Course content for SAGE Academy, inductions and other courses.",
    url: "https://skillslab.instructure.com/login/saml",
    icon: require("../images/icons/canvaslms.png"),
  },
  {
    key: "cira",
    title: "C.I.R.A.",
    description: "Customer Invoice Requisition Automation",
    url: "https://pw.sageautomation.com/ProjectInvoice/",
    icon: require("../images/icons/cira.svg"),
  },
  {
    key: "corporate_traveller",
    title: "Corporate Traveller",
    description: "Travel booking system",
    url: "https://yourct.com.au",
    icon: require("../images/icons/corporate-traveller.png"),
  },
  {
    key: "culture_amp",
    title: "Culture Amp",
    description: "Employee engagement platform",
    url: "https://gotosage.cultureamp.com/app/home",
    icon: require("../images/icons/cultureamp.png"),
  },
  {
    key: "docalloy",
    title: "Document Generation Tool",
    description:
      "Document generation tool for creating documents with the latest branding and styles.",
    url: "https://docs.gotosage.com/",
    icon: require("../images/icons/docalloy.svg"),
  },
  {
    key: "gitlab",
    title: "GitLab",
    description:
      "Web-based DevOps lifecycle tool that provides a Git-repository manager and CI/CD pipeline features",
    url: "https://git.gotosage.com",
    icon: require("../images/icons/gitlab.png"),
  },
  {
    key: "jira",
    title: "Jira",
    description: "Issue and task management",
    url: "https://gotosage.atlassian.net/jira",
    icon: require("../images/icons/jira.png"),
  },
  {
    key: "last_pass",
    title: "LastPass",
    description: "Enterprise password management system",
    url: "https://lastpass.com/?ac=1&lpnorefresh=1",
    icon: require("../images/icons/lastpass.png"),
  },
  {
    key: "leap",
    title: "LEAP",
    description: "Process improvement ideas and non-conformance tracking",
    url: "https://gotosage.sharepoint.com/sites/leap",
    icon: require("../images/icons/sharepoint.png"),
  },
  {
    key: "legal",
    title: "Legal Requests",
    description: "Legal request form",
    url: "https://app.dazychain.com/#/forms/login",
    icon: require("../images/icons/legal.png"),
  },
  {
    key: "one_drive",
    title: "OneDrive",
    description: "File management and sharing",
    url: "https://gotosage-my.sharepoint.com",
    icon: require("../images/icons/onedrive.png"),
  },
  {
    key: "strategic_pillar",
    title: "Our Company Strategy",
    description: "Strategic Pillars & Initiatives",
    url: "https://gotosage.atlassian.net/wiki/spaces/a/pages/229766/Company+Strategy",
    icon: require("../images/icons/base-camp.png"),
  },
  {
    key: "outlook",
    title: "Outlook",
    description: "Email and calendar",
    url: "https://outlook.office365.com/owa/?realm=sageautomation.com&amp;exsvurl=1&amp;ll-cc=3081&amp;modurl=0",
    icon: require("../images/icons/outlook.png"),
  },
  {
    key: "project_workspace",
    title: "Project Workspace",
    description:
      "Project specific collaboration portals for storing documentation and tracking items",
    url: "https://projects.gotosage.com/",
    icon: require("../images/icons/project-workspace.svg"),
  },
  {
    key: "prophix",
    title: "Prophix",
    description: "Budgeting and forecasting",
    url: "http://cor-appsrv5",
    icon: require("../images/icons/prophix.png"),
  },
  {
    key: "rapid_induct",
    title: "RapidInduct",
    description: "Employee and contractor inductions",
    url: "https://my.rapidglobal.com/Web/Account/LogOn?ReturnUrl=%2fWeb%2fDashboard%2fRapidInductPackages",
    icon: require("../images/icons/rapidinduct.png"),
  },
  {
    key: "redmap",
    title: "Redmap",
    description: "AP automation for Pronto",
    url: "https://home.smartcloud.com.au/",
    icon: require("../images/icons/redmap.png"),
  },
  {
    key: "reports",
    title: "Reports",
    description: "Reporting system for data warehouse",
    url: "https://reports.sageautomation.com/Reports",
    icon: require("../images/icons/reports.png"),
  },
  {
    key: "salesforce",
    title: "Salesforce",
    description: "Service work order and resource scheduling",
    url: "https://gotosage.lightning.force.com/",
    icon: require("../images/icons/salesforce.png"),
  },
  {
    key: "sales_lead_form",
    title: "Sales Lead Form",
    description: "Submit a lead in CRM via Microsoft Forms",
    url: "https://forms.office.com/r/CGnDRDLyFZ",
    icon: require("../images/icons/sage-sickle.png"),
  },
  {
    key: "send_this_file",
    title: "SendThisFile",
    description: "Sending and receive large files",
    url: "https://www.gotosage.com/file-transfer",
    icon: require("../images/icons/sendthisfile.png"),
  },
  {
    key: "sharepoint_pw",
    title: "SharePoint Project Workspaces (Legacy)",
    description:
      "Project specific collaboration portals for storing documentation and tracking items",
    url: "https://projects.sageautomation.com",
    icon: require("../images/icons/sharepoint.png"),
  },
  {
    key: "skills",
    title: "Skills Search",
    description: "Search team member skills",
    url: "https://pw.sageautomation.com/TeamMemberSkill/TeamMemberSkillList",
    icon: require("../images/icons/skills.svg"),
  },
  {
    key: "teams",
    title: "Teams",
    description: "Teamwork, communication and collaboration",
    url: "https://teams.microsoft.com",
    icon: require("../images/icons/teams.png"),
  },
  {
    key: "transport_request_form",
    title: "Transport Request Form",
    description: "Ye Olde Transportation Requisition Parchment",
    url: "https://gotosage.sharepoint.com/sites/Warehouse/Shared%20Documents/General/Documents/All%20Company%20Files/Transport%20Request.xlsm?d=w84bdb6d7520c4464a1d24c4ec2a64ccb",
    icon: require("../images/icons/sharepoint.png"),
  },
  {
    key: "viva_engage",
    title: "Viva Engage",
    description: "Enterprise social networking",
    url: "https://engage.cloud.microsoft/main/org/gotosage.com/feed",
    icon: require("../images/icons/viva-engage.png"),
  },
  {
    key: "workday",
    title: "Workday",
    description: "Requesting leave and updating information",
    url: "https://wd3.myworkday.com/gotosage",
    icon: require("../images/icons/workday-icon.png"),
  },
  {
    key: "zoho_expense",
    title: "Zoho Expense",
    description: "Lodging and managing expense claims",
    url: "https://pw.sageautomation.com/TeamMember/GetYourZohoRegistration",
    icon: require("../images/icons/zohoexpense.png"),
  },
];
